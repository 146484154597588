import { Injectable } from "@angular/core";
import { Auction } from "@libs/models/auction";
import { of, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  finishedAuction$ = new ReplaySubject<any>(1);
  private _selectedAuctionsIds: string[];

  constructor() {}

  loadDataWithUser() {
    return of(null);
  }

  resetData() {}

  get selectedAuctionsIds(): string[] {
    if (this._selectedAuctionsIds) {
      return this._selectedAuctionsIds;
    } else {
      return JSON.parse(localStorage.getItem("selectedAuctionsIds") ?? "[]");
    }
  }
  set selectedAuctionsIds(auctionsIds: string[]) {
    this._selectedAuctionsIds = auctionsIds;
    localStorage.setItem("selectedAuctionsIds", JSON.stringify(auctionsIds));
  }
}
